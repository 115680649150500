//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContractDetail from "@/views/components/ContractDetail";
import {
  fetchCauseStep,
  fetchProvince,
  fetchTransportOfficeWithProvince,
} from "@/api/master";
import { fetchCarTransferDetail, carTransferSave } from "@/api/carTransfer";
import { Message } from "element-ui";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default {
  name: "TransferCarDetail",
  components: {
    ContractDetail,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes("CheckDocument");
    });
  },
  data() {
    var validateStatus = (rule, value, callback) => {
      if (value && value > 2) {
        callback(new Error('กรุณาเลือกสถานะของสัญญา'));
      } else {
        callback();
      }
    };
    var validateCause = (rule, value, callback) => {
      if (this.formData.statusId === 1 && value.length === 0) {
        callback(new Error('กรุณาเลือกสาเหตุ'));
      } else {
        callback();
      }
    };
    var validateDate = (rule, value, callback) => {
      if (this.formData.statusId === 2 && value === null) {
        callback(new Error('กรุณาเลือกวันที่'));
      } else {
        callback();
      }
    };
    var validateSelect = (rule, value, callback) => {
      if (this.formData.statusId === 2 && value === '') {
        callback(new Error('กรุณาเลือกจังหวัด/ขนส่งที่โอนรถ'));
      } else {
        callback();
      }
    };
    var validateImage = (rule, value, callback) => {
      if (this.formData.statusId === 2 && this.formData.images.length === 0 && this.temp.attachments.length === 0) {
        callback(new Error('กรุณาเพิ่มรูปภาพ'));
      } else {
        callback();
      }
    };
    return {
      validate: false,
      fields: [
        { key: "id", label: "", _style: "width:100px" },
        { key: "name", label: "รายการ", _style: "min-width:100px" },
        { key: "price", label: "จำนวนเงิน", _style: "width: 200px" },
      ],
      items: [],
      expenses: [
        { id: "1", name: "ค่าโอน (ตามใบเสร็จของขนส่ง) 1", price: 0 },
        { id: "2", name: "ค่าโอน (ตามใบเสร็จของขนส่ง) 2", price: 0 },
        { id: "3", name: "ค่าตรวจสภาพรถ", price: 0 },
        { id: "4", name: "ไม่เอารถเข้าตรวจ", price: 0 },
        { id: "5", name: "ค่าบริการขนส่ง", price: 0 },
        { id: "6", name: "ค่าอากรมอบอำนาจ", price: 0 },
        { id: "7", name: "ค่าอากรสัญญา", price: 0 },
        { id: "8", name: "ต่อภาษี", price: 0 },
        { id: "9", name: "พรบ.", price: 0 },
        { id: "10", name: "ตรวจตรอ.", price: 0 },
        { id: "11", name: "ค่าGPS.", price: 0 },
        { id: "12", name: "แจ้งย้าย", price: 0 },
        { id: "13", name: "ฝากตรวจ", price: 0 },
        { id: "14", name: "อื่นๆ", price: 0 },
      ],
      status: [
        { value: 4, label: "นัดหมายแล้ว" },
        { value: 2, label: "เรียบร้อย" },
        { value: 1, label: "ไม่เรียบร้อย" },
      ],
      causeOption: [],
      province: [],
      transport: [],
      isLoading: false,
      dialogImageUrl: "",
      dialogVisible: false,
      temp: {
        attachments: [],
      },
      contractParam: {
        contractId: this.$route.params.id,
      },
      causeParam: {
        stepId: 4,
      },
      balanceFormat: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      formData: {
        contractId: "",
        statusId: null,
        transferDate: null,
        provinceId: "",
        transportId: "",
        remark2: "",
        stepdCauseId: "",
        stepdCostId: "",
        stepdImageId: "",
        transferFee1: 0,
        transferFee2: "",
        vehicleInspectionFee: "",
        notInspectingFee: "",
        transportationService: "",
        dutyAuthorizeFee: "",
        dutyContractFee: "",
        vehicleTax: "",
        actFee: "",
        pviFee: "",
        gpsFee: "",
        moveFee: "",
        checkFee: "",
        other: "",
        cause: "",
        images: [],
        imagesDel: [],
      },
      transportOfficeParam: {
        provinceId: "",
        transportId: "",
      },
      priceTotal: 0,
      formRules: {
        contractTypeId: [
          { required: true, message: 'กรุณาเลือกประเภทสินเชื่อ', trigger: 'change' }
        ],
        statusId: [
          { required: true, validator: validateStatus, trigger: 'change' }
        ],
        cause: [
          { validator: validateCause, trigger: 'change' }
        ],
        transferDate: [
          { validator: validateDate, trigger: 'change' }
        ],
        provinceId: [
          { validator: validateSelect, trigger: 'change' }
        ],
        transportId: [
          { validator: validateSelect, trigger: 'change' }
        ],
        attachments: [
          { validator: validateImage, trigger: 'blur' }
        ],
      },
    };
  },
  computed: {
    contractDetail() {
      return this.items;
    },
  },

  async mounted() {
    this.$store.state.loading = true;
    try {
      await new Promise((resolve, reject) => {
        fetchCauseStep(this.causeParam)
          .then((response) => {
            const check = response.header;
            const data = response.body;
            if (check.error === "N") {
              this.causeOption = data;
              resolve();
            }
          })
          .catch((error) => {
            this.errorMsg = error;
            console.log(error);
            reject(error);
          });
        fetchProvince()
          .then((response) => {
            const check = response.header;
            const data = response.body;
            if (check.error === "N") {
              this.province = data;
              resolve();
            }
          })
          .catch((error) => {
            this.errorMsg = error;
            console.log(error);
            reject(error);
          });
      });
      await this.getCarTransferDetail();
    } finally {
      this.$store.state.loading = false;
    }
  },

  methods: {
    getCarTransferDetail() {
      fetchCarTransferDetail(this.contractParam)
        .then((res) => {
          this.items = [];
          const check = res.header;
          const data = res.body;
          if (check.error === "N") {
            this.formData.contractId = data[0].contract_id;
            this.formData.statusId = data[0].status_id;
            this.formData.cause = data[0].cause;
            this.formData.transferDate = data[0].transfer_date;
            this.formData.provinceId = data[0].province_id;
            this.formData.transportId = data[0].transport_id;
            this.formData.remark2 = data[0].remark2;
            this.formData.stepdCauseId = data[0].stepd_cause_id;
            this.formData.stepdCostId = data[0].stepd_cost_id;
            this.formData.stepdImageId = data[0].stepd_image_id;
            this.expenses[0].price = this.numberFormat(data[0].transfer_fee1) || 0;
            this.expenses[1].price = this.numberFormat(data[0].transfer_fee2) || 0;
            this.expenses[2].price = this.numberFormat(data[0].vehicle_inspection_fee) || 0;
            this.expenses[3].price = this.numberFormat(data[0].not_inspecting_fee) || 0;
            this.expenses[4].price = this.numberFormat(data[0].transportation_service) || 0;
            this.expenses[5].price = this.numberFormat(data[0].duty_authorize_fee) || 0;
            this.expenses[6].price = this.numberFormat(data[0].duty_contract_fee) || 0;
            this.expenses[7].price = this.numberFormat(data[0].vehicle_tax) || 0;
            this.expenses[8].price = this.numberFormat(data[0].act_fee) || 0;
            this.expenses[9].price = this.numberFormat(data[0].pvi_fee) || 0;
            this.expenses[10].price = this.numberFormat(data[0].gps_fee) || 0;
            this.expenses[11].price = this.numberFormat(data[0].move_fee) || 0;
            this.expenses[12].price = this.numberFormat(data[0].check_fee) || 0;
            this.expenses[13].price = this.numberFormat(data[0].other) || 0;
            this.totalPrice();
            this.items = data[0];
            this.temp.attachments = data[0].images;

            this.getTransportOfficeWithProvince(this.formData.provinceId);
            this.transportOfficeParam.provinceId = data[0].province_id;
            this.transportOfficeParam.transportId = data[0].transport_id;
          }
        })
        .catch((error) => {
          Message({
            message: "มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ",
            type: "error",
            duration: 5 * 1000,
          });
          this.errorMsg = error;
          console.log("error :", error);
        });
    },
    saveCarTransfer(expenses) {
      console.log("formData : ", this.formData);

      for (let index = 0; index < expenses.length; index++) {
        if (expenses[index].price.length > 6) {
          expenses[index].price = expenses[index].price
            .toString()
            .replace(/\,/g, "");
        }
      }
      this.formData.transferFee1 = this.expenses[0].price;
      this.formData.transferFee2 = this.expenses[1].price;
      this.formData.vehicleInspectionFee = this.expenses[2].price;
      this.formData.notInspectingFee = this.expenses[3].price;
      this.formData.transportationService = this.expenses[4].price;
      this.formData.dutyAuthorizeFee = this.expenses[5].price;
      this.formData.dutyContractFee = this.expenses[6].price;
      this.formData.vehicleTax = this.expenses[7].price;
      this.formData.actFee = this.expenses[8].price;
      this.formData.pviFee = this.expenses[9].price;
      this.formData.gpsFee = this.expenses[10].price;
      this.formData.moveFee = this.expenses[11].price;
      this.formData.checkFee = this.expenses[12].price;
      this.formData.other = this.expenses[13].price;

      this.$refs['detailForm'].validate((valid) => {
        if (valid) {
          carTransferSave(this.formData)
              .then((response) => {
                const check = response.header;
                //   const data = response.body;
                if (check.error === "N") {
                  Message({
                    message: "บันทึกข้อมูลสำเร็จ",
                    type: "success",
                    duration: 2 * 1000,
                    onClose: () => {
                      this.goBack();
                    },
                  });
                }
              })
              .catch((error) => {
                Message({
                  message: "มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ",
                  type: "error",
                  duration: 2 * 1000,
                });
                this.errorMsg = error;
                console.log(error);
              });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async getTransportOfficeWithProvince(provinceId) {
      await fetchTransportOfficeWithProvince({ provinceId: provinceId })
        .then((response) => {
          const check = response.header;
          const data = response.body;
          if (check.error === "N") {
            this.transport = data;
          }
        })
        .catch((error) => {
          Message({
            message: "มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ",
            type: "error",
            duration: 5 * 1000,
          });
          this.errorMsg = error;
          console.log("error :", error);
        });
      if (this.transportOfficeParam.provinceId !== this.formData.provinceId) {
        this.formData.transportId = null;
      } else {
        this.formData.transportId = this.transportOfficeParam.transportId;
      }
    },
    focusIn(price, index) {
      if (price.toString().length > 6) {
        price = price.toString().replace(/\,/g, "");
      }
    },
    numberWithCommas(price, index) {
      if (price.toString().length > 6) {
        price = price.toString().replace(/\,/g, "");
      }
      this.expenses[index].price = Number(price).toLocaleString(
        "en",
        this.balanceFormat
      );
    },
    numberFormat(price) {
        return Number(price).toLocaleString(
          "en",
          this.balanceFormat
          );
    },
    checkCause() {
      if (this.formData.statusId == 2) {
        this.formData.cause = [];
      }
    },
    async beforeUpload(file) {
      const data = await toBase64(file);
      this.formData.images.push(data);
      console.log("images : ", this.formData.images);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async handleRemove(file, fileList) {
      console.log(fileList);
      if (this.formData.images.length > 0) {
        for (let i = 0; i < this.formData.images.length; i++) {
          if (this.formData.images[i] === (await toBase64(file.raw))) {
            this.formData.images.splice(i, 1);
            i--;
          }
        }
      } else if (Number(this.temp.attachments.length) > 0) {
        for (let index = 0; index < this.temp.attachments.length; index++) {
          if (this.temp.attachments[index].name === file.name) {
            this.formData.imagesDel.push(file.name);
          }
        }
      }
      console.log("imagesDel : ", this.formData.imagesDel);
    },
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/step4/TransferCar" });
    },
    totalPrice() {
      let sum = 0;
      // calculate sum using forEach() method
      this.expenses.forEach( expense => {
        sum += Number(expense.price.toString().replace(/\,/g, ""));
      });
      
      this.priceTotal = this.numberFormat(sum);
    },
  },
};
