import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function fetchCarTransferList(params) {
    return request({
        url: baseURL + '/carTransfer/list',
        method: 'GET',
        params: params
    })
}

export function fetchCarTransferDetail(param) {
    return request({
        url: baseURL + '/carTransfer/detail',
        method: 'GET',
        params: param
    })
}

export function carTransferSave(data) {
    return request({
        url: baseURL + '/carTransfer/save',
        method: 'POST',
        data
    })
}